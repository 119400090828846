import Swiper from 'swiper';
import {Autoplay, EffectFade, Pagination} from 'swiper/modules';
import 'swiper/css';

$(document).ready(() => {
  const isTouchDevice = window.matchMedia('(pointer: coarse)').matches;

  // Accordion functionality
  var accordionOpenFirst = true;
  var accordionSpeed = 'slow';

  const swiper = new Swiper('.swiper', {
    // effect: 'fade',
    modules: [
      Autoplay,
      EffectFade,
      Pagination
    ],
    autoplay: {
      delay: 3000,
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    on: {
      autoplayStart: function (swiper) {
        swiper.el.classList.add('playing');
      },
      autoplayStop: function (swiper) {
        swiper.el.classList.remove('playing');
      }
    }
  });

  $(".swiper-button-pause, [data-lightbox]").click(function () {
    swiper.autoplay.stop();
  });

  $(".swiper-button-play").click(function () {
    swiper.autoplay.start();
  });

  $('.site-nav-toggle button').click(function () {
    $('body').toggleClass('offcanvas-active');
  });

  $('.ce_accordion .accordion').hide();

  if (accordionOpenFirst) {
    $('.ce_accordion:first').find('.toggler').toggleClass('opened').end()
      .find('.toggler ~ .accordion').slideDown();
  }

  $('.ce_accordion .toggler').click(function () {
    if ($(this).parent().find('.toggler ~ .accordion').is(":hidden")) {
      $('.toggler ~ .accordion').slideUp(accordionSpeed);
      $(this).parent().find('.toggler').toggleClass('opened').end()
        .find('.toggler ~ .accordion').slideDown(accordionSpeed);
    } else {
      $('.toggler ~ .accordion').slideUp(accordionSpeed);
    }
  });

  // Main image opacity animation
  $('#main img')
    .css({
      opacity: 0,
      visibility: 'visible',
      display: 'block'
    })
    .stop()
    .animate({
      "opacity": "1"
    }, "slow");

  // Slideshow functionality
  $('#slideshow').removeClass('nojs');
  $('#slideshow img').addClass('showimg');
  /* $('.slideshow > ul').fadeSlideShow({
      width: 640,
      height: 360,
      speed: 600,
      interval: 5000
  }); */

  $('#slideshow .openlightbox').css({opacity: 0, visibility: 'visible'}, {display: 'block'});
  $("#slideshow .openlightbox").hover(
    function () {
      $(this).stop().animate({"opacity": "1"}, "slow");
    },
    function () {
      $(this).stop().animate({"opacity": "0"}, "slow");
    }
  );

  if (!isTouchDevice) {
    let div = $('.gallery_scroller'),
      ul = $('.gallery_scroller > ul'),
      ulPadding = 15,
      divWidth = div.width(),
      lastLi = ul.find('li:last-child');

    div.css({overflow: 'hidden'});

    div.mousemove(function (e) {
      var ulWidth = lastLi[0].offsetLeft + lastLi.outerWidth() + ulPadding;
      var left = (e.pageX - div.offset().left) * (ulWidth - divWidth) / divWidth;
      div.scrollLeft(left);
    });
  }

  $(`
  .content-text a[href$=".jpg"],
  .content-text a[href$=".jpeg"],
  .content-text a[href$=".png"],
  .content-text a[href$=".gif"],
  .content-text a[href$=".JPG"],
  .content-text a[href$=".JPEG"],
  .content-text a[href$=".PNG"],
  .content-text a[href$=".GIF"]
`).colorbox({
    photo: true,
    maxWidth: '95%',
    maxHeight: '95%'
  });
});
